import { db } from "../firebase-config"
import { useState, useEffect } from "react";
import { collection, addDoc, query, where, onSnapshot, orderBy } from "firebase/firestore"
import { useParams } from "react-router";



function Existing() {

  const [ducks, setDucks] = useState([]);
  const duckCollectionRef = collection(db, "jdr")
  let { duckid } = useParams()
  const [duckDate, setDuckDate] = useState("")

  // const [duckDate, setDuckDate] = useState("");
  const [duckCity, setDuckCity] = useState("");
  const [duckState, setDuckState] = useState("");

  const ducksCollectionRef = collection(db, "jdr")

  const createDuckLocation = async () => {
    
    // var dCity = getElementById("duckCity").value;
    // var dState = getElementById("duckState").value;

    // duckCity = dCity.toUpperCase();
    // duckState = dState.toUpperCase();
    
    // console.log(getElementById("duckCity").value);

    if (duckCity == "" || duckState == "") {
      alert("City and/or State cannot be empty")
      window.location.href='/'+duckid
    }

    await addDoc(ducksCollectionRef,
      {
        duckCode: duckid,
        duckDate: duckDate,
        duckCity: duckCity,
        duckState: duckState
      }
    )
    
    document.getElementById("addLocation").style.display = "none";
    document.getElementById("addLocationMsg").style.display = "inline";
  
  }

  useEffect(() => {
    
    const q = query(duckCollectionRef, where("duckCode", "==", duckid), orderBy("duckDate", "desc"));  

    onSnapshot(q, (snapshot) => {
      
      if (snapshot.empty) {
        alert("no records found")
        window.location.href='/'
      } 
        
      setDucks(snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    
    }) 


    function getDate() {
    
      var month = new Date().getMonth();
      
      if (month < 10) {
        month = "0" + (month + 1)
      }
      
      var day = new Date().getDate();
      if (day < 10) {
        day = "0" + day
      }
      
      var year = new Date().getFullYear();
      var duckDate = year + "-" + month + "-" + day  
  
      setDuckDate(duckDate);
    
    }
  
    getDate()

  },[])

  

  return (
    <div className="container">
      <div className="content">
        
        <p>
          Hello!
        </p>

        <p>
          Thank you for helping me along in my journey! I have already been to the locations listed below and now you can help me
          keep the journey alive!
        </p>
    
        <p>
          Please enter the city and state where you found me and press "submit my location".
        </p>

        <table>
          <tbody>
            <tr>
              <th>Duck Code</th>
              <th>Date</th>
              <th>City</th>
              <th>State</th>
            </tr>
          </tbody>
        </table>

        {ducks.map((duck) => {
        
          return(
            <div> 

              <table>
                <tr>
                  <td>{duck.data.duckCode}</td>
                  <td>{duck.data.duckDate}</td>
                  <td>{duck.data.duckCity}</td>
                  <td>{duck.data.duckState}</td>
                </tr>
              </table>
                    
            </div>
          );

        })}

        <div className="addLocation" id="addLocation">
          
          <input type="text" id="duckCity" placeholder="Enter city" onChange={(event) => {setDuckCity(event.target.value.toUpperCase())}} required />
          <input type="text" id="duckState" placeholder="Enter state (two letter)" onChange={(event) => {setDuckState(event.target.value.toUpperCase())}} required />
          <button id="saveducklocation" onClick={createDuckLocation}>submit my location</button>
          
        </div>

        <div className="addLocationMsg">
          <label id="addLocationMsg" style={{display: "none"}}>Duck saved successfully</label>
        </div>

      </div>
    </div>
  );
}
  
  export default Existing;
function About() {
    return (
      <div className="container">
        <div className="content">
          <h1>About</h1>
          <p>
            Hey there!
            My name is Joe Barbone and I'm a web developer. I also drive a Jeep. As you can imagine, I have aquired quite a few ducks.
            I also pass them along from time to time, but have often wondered where they go? This is where computer geek meets Jeep enthusiast.
            
          </p>

          <p>
            I am learning React and Firebase at the moment and I thought this would be a fun little exercise to help me learn basic CRUD 
            functionality. CRUD stands for Create, Read, Update and Delete, essential components for databases and data storage. I figured this
            would be a good place to start. 
          </p>

          <p>
            The Jeep Duck Registry is a fairly basic web app that allows you to scan your QR code to see where your duck has traveled. It also
            has the capability to enter the city and state where your Jeep was "ducked". Once you registered the location, feel free to take
            your ducks and show them around town, but please don't hang on to them for too long, they have places to go and people to
            meet!  
          </p>

          <p>
            The JDR is just a little project that I created to help me learn some new programming skills while incorporating something
            fun that I could relate to in the process. I am starting out with 10 ducks that I have received over time. Each duck will have
            a label with a QR code that can be scanned and will take you to that ducks information page. Each duck will originate from
            Ormond Beach, FL and will contain a record of each time the duck was scanned and a new location was recorded. Since I only ask
            for the city and state, there is no other way for you to track the ducks you find and record other than noting their individual 8
            character duck code. You can visit the site at any time and enter the duck code to see where the duck has traveled.
          </p>

          <p>
            Please let me know if you have any questions or comments or if you find a bug within the site. This site will evolve as
            I learn new skills so there may be additional functionality added as I go along. Thanks for visiting!
          </p>

          <p>
            Joe Barbone<br />
            <a href="mailto:JoeBarbone@yahoo.com">JoeBarbone@yahoo.com</a><br />
          </p>
        </div>
      </div>
    );
  }
  
  export default About;
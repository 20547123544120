import { Link } from "react-router-dom"

function Footer() {
    return (
      <footer>
        
        <div className="footerInfo">
          <p>Midnight Angel Web Development</p>
          <p>Ormond Beach, Florida</p>
        </div>

        <div className="disclaimer">
          <p>Not affiliated with FCA US LLC | <Link to="/disclaimer"><strong>Disclaimer</strong></Link></p>
        </div>

      </footer>
    );
    
  }
  
  export default Footer;
// import './App.css';
import { db } from "../firebase-config"
import { useState, useEffect } from "react";
import { collection, getDocs, orderBy } from "firebase/firestore"

import React from "react"

function Admin() {

  const [ducks, setDucks] = useState([]);
  const duckCollectionRef = collection(db, "jdr")

  useEffect(() => {

    const getDucks = async () => {
  
      const data = await getDocs(duckCollectionRef);
  
      setDucks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  
    };
  
    getDucks()

  }, [])



  return (
    <div className="container">
      <div className="content">
        
        <p>
          All duck records
        </p>
        
        <table>
          <tbody>
            <tr>
              <th>Duck Code</th>
              <th>Duck Date</th>
              <th>Duck City</th>
              <th>Duck State</th>
              <th>Duck ID</th>
            </tr>
          </tbody>
        </table>

        {ducks.map((duck) => {
        
          return(
            <div> 

              <table>
                <tr>
                  <td>{duck.duckCode}</td>
                  <td>{duck.duckDate}</td>
                  <td>{duck.duckCity}</td>
                  <td>{duck.duckState}</td>
                  <td>{duck.id}</td>
                </tr>
              </table>
                    
            </div>
          );

        })}

      </div>
    </div>
  );
};

export default Admin;

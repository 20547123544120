import React from "react"
import { useState } from "react"

function Home() {
  const [duckCode, setDuckCode] = useState("");

  function handleClick() {
    
    var dc = document.getElementById("duckCode").value;
    dc = dc.toUpperCase();
    setDuckCode(dc);
    window.location.href='/'+dc

  }  

  return (

    <div className="container">
    
      <div className="content">
        
        <p>
          Welcome to the Jeep Duck Registry!
        </p>
  
        <p>
          Follow the travels of your Jeep ducks! While everyone loves getting "ducked", many people choose to keep their ducks on their
          dash, however, these are "nomad" ducks and love to travel! Feel free to provide them a good home for a day or two, but
          please resist the urge to keep them sheltered and in one place.
        </p>

        <p>
          Send them on an adventure and see where they wind up!
        </p>
  
        <hr />
  
        <p>
          To see your duck's journey, simply enter the code below and follow along.
        </p>
        
        <div className="findmyduck">
          <input type="text" id="duckCode" placeholder="8 character duck code" />
          <button id="findmyduck" onClick={handleClick}>Find My Duck!</button>
        </div>
  
      </div>
  
    </div>
  
  );
}
  
  export default Home;
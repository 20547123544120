function GenerateID() {

    var duckid = [];
    const charPool = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
    
    for(var x=0; x<8; x++) {
        var char = Math.floor(Math.random() * 26);
        console.log(charPool[char]);
        duckid.push(charPool[char]);  
        console.log(duckid.join(""));
        document.getElementById("duckid").textContent = duckid.join("");
    }
    
}


function Generate() {
    return (
      <div className="content">
        <h1>
          Generate and tag your own ducks
        </h1>
        <p>
            Use this page to generate a new Duck ID. You can then register your duck with the ID and send it on its way. It's easy to
            see where your duck goes, just visit the main site and enter the 8 character Duck ID and you will see the travel history.
        </p>
        <div className="generateid">
            <p>
                <label id="duckid">Your DuckID will appear here</label>
            </p>
            <p>
                <button onClick={GenerateID}>Generate Duck ID</button>
            </p>
        </div>
      </div>
    );
  }
  
  export default Generate;
function Contact() {
    return (
      <div className="content">
        <h1>Contact</h1>

        <div className="contactInfo">
          <div>
            <img src="https://media.nationalgeographic.org/assets/photos/223/386/10ce445a-e6e8-481f-9e39-f88ae80966a6.jpg" alt="rubber ducks" />
            {/* <img src="https://m.media-amazon.com/images/I/71afRpAImqL._AC_UF894,1000_QL80_.jpg" alt="rubber ducks" /> */}
          </div>

          <div className="formData">
            <form action="#">

              <label htmlFor="firstName">First Name</label>
              <input type="text" id="firstName"/>

              <label htmlFor="lastName">Last Name</label>
              <input type="text" id="lastName"/>
              
              <label htmlFor="email">Email</label>
              <input type="email" id="email"/>
              
              <label htmlFor="message">Message</label>
              <input type="text" id="message"/>
            
            </form>
            <button type="submit">Submit</button>
          </div>
        </div>
      </div>
    );
  }
  
  export default Contact;
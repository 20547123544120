// import logo from "../jdr_logo.jpg"
import logo from "../rubber_duck_logo_yellow.jpg"
import { Link } from "react-router-dom"

function Navbar() {

  return (
    <div>
      <div className="navcontainer">

          <div className="header-divs left-menu">
            
            <a href="/">
              <h1 className="fulltitle">Jeep Duck Registry</h1>
              <h1 className="shorttitle">JDR</h1>
            </a>

          </div>

          <div className="header-divs">
            <a href="/">  
              <img className="ducklogo" src={logo} alt="jeep duck registry logo" />
            </a>
          </div>

          <div className="header-divs right-menu" >
                
              <ul>

                {/* <li><Link to="/">Home</Link></li> */}
                {/* <li><Link to="/contact">Contact</Link></li> */}
                <li><Link to="/about"><h1>About</h1></Link></li>
                {/* <li><Link to="/generate">Generate</Link></li> */}
                {/* <li><Link to="/existing">Existing</Link></li> */}

              </ul>

          </div>

      </div>

    </div>
  );
}

export default Navbar;
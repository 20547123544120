function Nopage() {

    return (

      <div>
        <h1>Error 404: Page not found</h1>
      </div>
    
    );
    
  }
  
  export default Nopage;
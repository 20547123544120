function Disclaimer() {
    return (

      <div className="container">

        <div className="content">
          
          <h1>Disclaimer</h1>
          <p>
            JDR/Jeep Duck Registry is not affiliated with Fiat Chrysler Automobile, FCA US LLC, Chrysler Group LLC, or any of their affiliates, partners,
            contractors or any other related brands or companies. 
          </p>
          <p>
            JDR/Jeep Duck Registry is for entertainment purposes only and by using this site you understand that JDR/Jeep Duck Registry
            and Joseph Barbone have no connection to the above mentioned company/companies. Jeep is a registered trademark of Chrysler 
            Group LLC. 
          </p>
        
        </div>
      
      </div>
    
    );
  }
  
  export default Disclaimer;
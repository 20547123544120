import './App.css';
// import "./NewHome.css";
import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import About from "./pages/About"
import Contact from "./pages/Contact"
import Home from "./pages/Home"
import Existing from "./pages/Existing"
import Generate from "./pages/Generate"
import Nopage from "./pages/Nopage"
import Navbar from "./pages/Navbar"
import Footer from './pages/Footer';
import Admin from "./pages/Admin";
import Disclaimer from './pages/Disclaimer';
import NewHome from "./pages/NewHome"

function App() {

  return (
    <div>
    
      <Router>
        <Navbar />        
        
        <Routes>
          <Route path="/" element={<NewHome />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route exact path="/:duckid" element={<Existing />} />
          <Route path="/generate" element={<Generate />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="*" element={<Nopage />} />
        </Routes>
        <Footer />
      </Router>
     
    </div>  
  
  )
};

export default App;
